import { CommonModule } from "@angular/common";
import { Component, Input, Optional, Self } from "@angular/core";
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NgControl } from "@angular/forms";

import general from "@assets/constantes/general.json";

import { Option } from "@shared/Entities/General/option.entity";
import { UsuariosRepository } from "@shared/Repositories/usuarios.repository";
import { UnitSharedService } from "@Unit/shared/services/unitshared.service";

@Component({
  selector: "app-option-tabs",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./option-tabs.component.html",
  styleUrls: ["./option-tabs.component.scss"],
})
export class OptionTabsComponent implements ControlValueAccessor {
  public optionControl = new UntypedFormControl();
  public _options: Option[];
  public isUnitPortalStyle: boolean = false;



  @Input() public title: string;
  @Input() public bindLabel?: string;
  @Input() public bindValue?: string;

  @Input() public set options(newOptions: any[] | "boolean") {
    if (newOptions === "boolean") {
      this._options = general.booleanOptions;
      this.bindLabel = "nombre";
      this.bindValue = "codigo";
    } else {
      this._options = newOptions;
    }
  }

  @Input() public get required(): boolean {
    const validators = this.ngControl?.control?.validator;
    return this._required ?? validators ? validators({} as AbstractControl)?.hasOwnProperty("required") : false;
  }

  public set required(value: boolean) {
    this._required = value;
  }

  protected _required: boolean | undefined;

  constructor(@Self() @Optional() private ngControl: NgControl, private unitSharedService: UnitSharedService) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.unitSharedService.isUnitPortalStyle$.subscribe((isUnit) => {
      this.isUnitPortalStyle = isUnit;
    });
  }

  public onTouched = (): void => {
    this.optionControl.markAsTouched();
  };

  public writeValue(value: Record<string, string>): void {
    this.optionControl.setValue(value);
  }

  public registerOnChange(fn: (value: Record<string, string>) => void): void {
    this.optionControl.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled
      ? this.optionControl.disable({ emitEvent: false, onlySelf: true })
      : this.optionControl.enable({ emitEvent: false, onlySelf: true });
  }

  public switchValue(newValue: string): void {
    this.optionControl.setValue(newValue === this.optionControl.value ? null : newValue);
  }
}
