import { Location } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

import { AuthService } from "@shared/Services/auth.service";

import { UnitSharedService } from "@Unit/shared/services/unitshared.service";
@Component({
  selector: "app-inicio-header",
  templateUrl: "./inicio-header.component.html",
  styleUrls: ["./inicio-header.component.scss"],
})
export class InicioHeaderComponent {
  public mobileMenu: boolean;
  public logoValue: any;
  private isUnitPortalStyle: boolean;

  public readonly LOGOS: any = {
    Universal: { logo: "assets/images/universal-logo.svg", url: "/" },
    ARS: { logo: "assets/images/logo-ars-universal.svg", url: "https://www.arsuniversal.com.do/" },
    Unit: { logo: "assets/images/unit-desktop-logo.svg", url: "/unit" },
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private unitSharedService: UnitSharedService
  ) {
    this.unitSharedService.isUnitPortalStyle$.subscribe((isUnit) => {
      this.isUnitPortalStyle = isUnit;
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateLogo();
      }
    });
  }

  @Input() public set logo(value: string) {
    this.logoValue = this.LOGOS[value] || this.LOGOS["Universal"];
  }

  public login(): void {
    this.authService.login();
  }

  public toggleMenu(): void {
    this.mobileMenu = !this.mobileMenu;
  }

  public isUnitUrl(): boolean {
    return this.isUnitPortalStyle;
  }

  private updateLogo(): void {
    this.logoValue = this.isUnitPortalStyle
      ? this.LOGOS["Unit"]
      : this.location.path() === "/"
      ? this.LOGOS["Universal"]
      : this.location.path() === "https://www.arsuniversal.com.do/"
      ? this.LOGOS["ARS"]
      : this.LOGOS["Universal"];
  }
}
