<header class="header-container">
  <div class="container">
    <nav class="navbar navbar-expand-sm navbar-light">
      <a [href]="logoValue.url"><img alt="Logo Universal" class="universal-logo-home" [src]="logoValue.logo" /></a>
      <button (click)="toggleMenu()" class="navbar-toggler" type="button" data-toggle="collapse"
        data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="align-items-center justify-content-end navbar-collapse collapse" [ngClass]="{ show: mobileMenu }"
        id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item active ml-md-3">
            <a (click)="login()" class="nav-link login-outline-button"
            [ngClass]="{'button-unit': isUnitUrl()}" 
            [routerLink]="[]">
            Iniciar Sesión
         </a>         
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>