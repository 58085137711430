import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { UiSharedModule, UniFormFieldModule, UniInputModule, UniSelectModule } from "@universal-ui";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { NgxFileDropModule } from "ngx-file-drop";

import { SharedModule } from "@shared/Modules/shared.module";

import { AfiliadosSearchComponent } from "./Components/AfiliadosSearch/afiliados-search.component";
import { DateInputComponent } from "./Components/DateInput/date-input.component";
import { FileInputComponent } from "./Components/FileInput/file-input.component";
import { OptionTabsComponent } from "./Components/OptionTabs/option-tabs.component";
import { SearchByDropDownComponent } from "./Components/SearchByDropDown/search-by-drop-down.component";
import { SearchInputComponent } from "./Components/SearchInput/search-input.component";
import { InputValidationDirective } from "./Directives/input-validation.directive";
import { OnlyNumbersDirective } from "./Directives/only-numbers.directive";

@NgModule({
  declarations: [
    //Components
    AfiliadosSearchComponent,
    SearchByDropDownComponent,
    DateInputComponent,
    FileInputComponent,

    //Directives
    InputValidationDirective,
    OnlyNumbersDirective,
    SearchInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    OptionTabsComponent,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NgxFileDropModule,
    UniSelectModule,
  ],
  exports: [
    //Components
    AfiliadosSearchComponent,
    OptionTabsComponent,
    SearchByDropDownComponent,
    DateInputComponent,
    FileInputComponent,

    //Directives
    InputValidationDirective,
    OnlyNumbersDirective,

    UniFormFieldModule,
    UniInputModule,
    UiSharedModule,
  ],
})
export class FormSharedModule {}
