import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";

import { UnitSharedService } from "@Unit/shared/services/unitshared.service";

@Component({
  selector: "uni-step-header",
  templateUrl: "./step-header.component.html",
  styleUrls: ["./step-header.component.scss"],
})
export class UniStepHeaderComponent {
  @Input() public label: string;
  @Input() public icon: string;
  @Input() public index: number;
  @Input() public active: boolean = false;
  @Input() public showIndex: boolean = true;
  @Input() public dataTarget: string;

  @Output() public clickStep = new EventEmitter<number>();

  @HostBinding("class.step") public step = true;
  @HostBinding("attr.data-target") public get dataTargetId(): string {
    return "#" + this.dataTarget;
  }

  public isUnitPortalStyle: boolean = false;

  constructor(private unitSharedService: UnitSharedService) {
    this.unitSharedService.isUnitPortalStyle$.subscribe((isUnit) => {
      this.isUnitPortalStyle = isUnit;
    });
  }

  public onClickStep(index: number): void {
    this.clickStep.emit(index - 1);
  }
}
